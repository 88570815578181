html,
body {
    height: 100%;
}

#root {
    min-height: 100%;
    height: 100%;
    overflow: initial;
    /* display: flex; */
    flex-direction: column;
}


a {
    color: #05b2df;
    text-decoration: none;
}


.app {
    margin-bottom: auto;

    min-height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
    margin: 24px 0;
}

footer {
    flex-shrink: 0;
    padding: 24px;
    text-align: center;
    background-color: #222;
    color: #ccc;
}

nav {
    background-color: #05b2df;
}

.navbar-nav>.nav-item>.nav-link {
    color: #fff
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: #fff
}

.App-logo {
    max-width: 100px;
}

.btn+.btn {
    margin-left: 10px;
}

li.with-cart {
    position: relative;
}

div#cart {
    position: absolute;
    width: 600px;
    right: 0;
    background: #fff;
    padding: 16px;
    border: 1px solid #eee;
    z-index: +111;
    display: none;
}

li.with-cart:hover #cart {
    display: block;
}

.product .product-categories a {
    margin-right: 10px;
}

.product .card-price {
    font-size: 24px;
}

.product {
    height: 100%;
    background-color: #fafafa;
    border: 1px solid #ececec;
    border-radius: 3px;
}

.product>.row {
    height: 100%;
}

.product .card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.product .btn-to-cart {
    max-width: 200px;
}

.product img {
    padding-left: var(--bs-card-spacer-x);
}

.form-group {
    margin-bottom: 10px;
}

.form-error {
    color: #dc3545;
    font-style: italic;
    font-size: 14px;
}

.group-item,
.user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background: #cbdde2;
    padding: 5px 10px;
}

.group-item:hover,
.user-item:hover {
    background: #b5ccd2;
}

.grouped-checkout-item {
    padding: 16px 24px;
    background: #fafafa;
    margin-bottom: 16px;
    border: 1px solid #ececec;
}



.checkout-item {
    background: #dbf1f7;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ececec;
}

.checkout-item:hover {
    background: #9bdef0;
}

.subtotal-checkout,
.subtotal-checkout:hover {
    background: none;
    border-color: transparent;
    border-top: 2px solid #eee;
    margin-bottom: 0;
}

.price-list-category {
    background: #fafafa;
    padding: 16px 24px;
    border: 1px solid #ececec;
}

.price-list-category .price-list-category {
    padding: 16px 0;
    border: none;
}

.price-list-product {
    background: #dbf1f7;
    padding: 3px 10px;
    margin-bottom: 5px;
    border: 1px solid #ececec;
}

.price-list-product:hover {
    background: #9bdef0;

}

.price-list-category .col-2,
.price-list-category .col-6 {
    text-align: center;
}

h1 {
    margin-bottom: 24px;
}

.checkout-item .form-control {
    max-width: 300px;

}

.checkout-item .subtotal {
    min-width: 100px;

}

.checkout-item .form-control-number {
    width: 70px;
}

.checkout-item.deleted-item {
    background: #ccc;
    color: #666;
    font-style: italic;
}
.checkout-item.deleted-item .form-control{ 
    background-color: #ddd;
}


.loading-spinner {
    font-size: 50px;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgb(73 81 92 / 20%);
    z-index: 99999999999999;
}

.order-form textarea {
    resize: none;
}

.option_1 {
    max-width: 121px;
    min-width: 121px;
    text-align: center;
    width: 100%;
}

.option_2 {
    max-width: 250px;
    min-width: 250px;
    text-align: center;
    width: 100%;
}

.option_3 {
    max-width: 221px;
    min-width: 221px;
    text-align: center;
    width: 100%;
}

.option_4 {
    max-width: 250px;
    min-width: 250px;
    text-align: center;
    width: 100%;
}

.alert p:last-child {
    margin-bottom: 0;
}

.order-item {
    background: #dbf1f7;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ececec;
}

.user-menu {
    flex-direction: row !important;
}

.totop {
    position: fixed;
    z-index: 599;
    bottom: 100px;
    right: 20px;
}

.gototop {
    background: rgb(219 241 247 / 80%);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    z-index: 9;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    transition: all .3s ease-in-out;
    border-radius: 50%;
}

.checkout-category {
    padding: 16px 24px;
    background: #96dbed;
    margin-bottom: 24px;
    border: 1px solid #ececec;
}


.orderColumn {
    padding: 10px 16px;
    border: 1px solid #ccc;
    height: 100%;
}

.orderColumn.dragging {
    background: #eee;
    border: 2px dashed rgb(196, 228, 196);
}

.orders-kanban .card {
    border-radius: 0;
    cursor: move;
}

.groupped-in-cat-order-items {
    background: #eff9fb;
    padding: 10px 16px;
    margin-bottom: 16px;
}

.cat_items .btn {
    margin-right: 10px;
}

.cat_items .btn+.btn {
    margin-left: 0;
}

.w800 {
    max-width: 800px;
    margin: 0 auto;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 16px;
        padding-left: 16px;
    }
}